<template>
    <div class="dashboard">
        <div class="container-fluid">
            <div class="content-area">
                <div class="dashboard-content">
                    <div class="dashboard-header clearfix">
                        <div class="row">
                            <div class="col-md-6 col-sm-12">
                                <h4>الإعلانات المفضلة</h4>
                            </div>
                            <div class="col-md-6 col-sm-12">
                                <div class="breadcrumb-nav">
                                    <ul>
                                        <li>
                                            <router-link to="/my"
                                                >الرئيسية</router-link
                                            >
                                        </li>
                                        <li class="active">
                                            الإعلانات المفضلة
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="column col-lg-12">
                            <div class="properties-box">
                                <div class="title">
                                    <h3>قائمة الإعلانات المفضلة</h3>
                                </div>
                                <div class="inner-container">
                                    <template v-if="properties.length > 0">
                                        <!-- Property Block -->
                                        <div
                                            v-for="(
                                                property, index
                                            ) in properties"
                                            :key="property.id"
                                            class="property-block"
                                        >
                                            <div class="inner-box clearfix">
                                                <div class="image-box">
                                                    <figure class="image">
                                                        <img
                                                            :src="
                                                                property.image_url
                                                            "
                                                            alt=""
                                                        />
                                                    </figure>
                                                    <ul class="info clearfix">
                                                        <li class="text-left">
                                                            <div
                                                                class="rating text-info"
                                                            >
                                                                <span
                                                                    :class="
                                                                        property.average_overall_rating >=
                                                                        1
                                                                            ? 'la la-star'
                                                                            : 'la la-star-o'
                                                                    "
                                                                ></span>
                                                                <span
                                                                    :class="
                                                                        property.average_overall_rating >=
                                                                        2
                                                                            ? 'la la-star'
                                                                            : 'la la-star-o'
                                                                    "
                                                                ></span>
                                                                <span
                                                                    :class="
                                                                        property.average_overall_rating >=
                                                                        3
                                                                            ? 'la la-star'
                                                                            : 'la la-star-o'
                                                                    "
                                                                ></span>
                                                                <span
                                                                    :class="
                                                                        property.average_overall_rating >=
                                                                        4
                                                                            ? 'la la-star'
                                                                            : 'la la-star-o'
                                                                    "
                                                                ></span>
                                                                <span
                                                                    :class="
                                                                        property.average_overall_rating >=
                                                                        5
                                                                            ? 'la la-star'
                                                                            : 'la la-star-o'
                                                                    "
                                                                ></span>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="content-box">
                                                    <h3>
                                                        {{ property.title }}
                                                    </h3>
                                                    <div class="location">
                                                        <i
                                                            class="la la-map-marker"
                                                        ></i>
                                                        {{ property.address }}
                                                    </div>
                                                    <ul
                                                        class="property-info clearfix"
                                                    >
                                                        <li>
                                                            <i
                                                                class="flaticon-users"
                                                            ></i>
                                                            {{
                                                                property.guests
                                                            }}
                                                        </li>
                                                        <li>
                                                            <i
                                                                class="flaticon-bed"
                                                            ></i>
                                                            {{ property.beds }}
                                                        </li>
                                                        <li>
                                                            <i
                                                                class="la la-wifi"
                                                            ></i>
                                                            {{
                                                                property.wifi
                                                                    ? "متوفر"
                                                                    : "غير متوفر"
                                                            }}
                                                        </li>
                                                        <li>
                                                            <i
                                                                class="flaticon-bathtub"
                                                            ></i>
                                                            {{
                                                                property.bathrooms
                                                            }}
                                                        </li>
                                                    </ul>
                                                    <div class="price">
                                                        $ {{ property.price_1 }}
                                                    </div>
                                                </div>
                                                <div class="option-box">
                                                    <ul class="action-list">
                                                        <li>
                                                            <a
                                                                :href="
                                                                    '/property/' +
                                                                    property.id
                                                                "
                                                                ><i
                                                                    class="la la-eye"
                                                                ></i>
                                                                عرض</a
                                                            >
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <div class="text-center" v-else>
                                        لا توجد إعلانات مفضلة بعد
                                    </div>
                                </div>

                                <div class="text-center mt-2">
                                    <button
                                        v-if="more"
                                        @click="getFavourites(currentPage + 1)"
                                        class="btn btn-oval btn-primary edit text-white"
                                    >
                                        <i
                                            class="fa fa-plus"
                                            aria-hidden="true"
                                        ></i>
                                        المزيد
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "my.favourite-properties",
    data() {
        return {
            currentPage: 1,
            more: null,
            properties: [],
        };
    },
    mounted() {
        this.getFavourites();
    },
    methods: {
        getFavourites(page = 1) {
            this.$axios
                .get("my/favourite-properties?page=" + page)
                .then((res) => {
                    this.currentPage = res.data.properties.current_page;
                    this.more = !!res.data.properties.next_page_url;
                    this.properties = this.properties.concat(
                        res.data.properties.data
                    );
                });
        },
    },
};
</script>

<style scoped>
.property-block .image-box .info {
    bottom: 0;
    direction: ltr;
}
</style>